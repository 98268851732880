import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Icon, Segment } from 'semantic-ui-react';
import html2canvas from 'html2canvas';
import './SharePreview.css';

function SharePreview() {
  const location = useLocation();
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const { clothesData } = location.state || {};

  useEffect(() => {
    if (!clothesData) {
      navigate('/my-closet');
    }
  }, [clothesData, navigate]);

  const generateImage = async () => {
    if (cardRef.current) {
      try {
        const canvas = await html2canvas(cardRef.current, {
          useCORS: true,
          allowTaint: true,
          scale: 2
        });
        return canvas.toDataURL('image/jpeg', 1.0);
      } catch (error) {
        console.error('Error generating image:', error);
        return null;
      }
    }
  };

  const shareToSocial = async (platform) => {
    const imageData = await generateImage();
    if (!imageData) return;

    switch (platform) {
      case 'instagram':
        // For Instagram, we need to use their sharing API
        if (navigator.share) {
          try {
            const blob = await (await fetch(imageData)).blob();
            const file = new File([blob], 'clothes.jpg', { type: 'image/jpeg' });
            await navigator.share({
              files: [file],
              title: clothesData.clothesName,
              text: 'Check out my closet on SheYou!'
            });
          } catch (error) {
            console.error('Error sharing to Instagram:', error);
          }
        } else {
          window.open('instagram://camera');
        }
        break;

      case 'facebook':
        const fbShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`;
        window.open(fbShareUrl, '_blank');
        break;

      case 'twitter':
        const twitterText = `Check out my closet on SheYou! ${clothesData.clothesName}`;
        const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(twitterText)}&url=${encodeURIComponent(window.location.href)}`;
        window.open(twitterUrl, '_blank');
        break;

      default:
        if (navigator.share) {
          try {
            await navigator.share({
              title: clothesData.clothesName,
              text: 'Check out my closet on SheYou!',
              url: window.location.href
            });
          } catch (error) {
            console.error('Error sharing:', error);
          }
        }
    }
  };

  if (!clothesData) return null;

  return (
    <div className="share-preview-container">
      <Icon 
        name="arrow left" 
        size="large" 
        className="back-button"
        onClick={() => navigate(-1)}
      />
      
      <div className="share-card" ref={cardRef}>
        <img 
          src={clothesData.image} 
          alt={clothesData.clothesName} 
          className="share-image"
        />
        <div className="share-info">
          <img 
            src="https://res.cloudinary.com/sheyou/image/upload/v1733423752/logo_0e8ee2e437.jpg"
            alt="SheYou Logo"
            className="share-logo"
          />
          <h2>{clothesData.clothesName}</h2>
          <p>Shared by: {localStorage.getItem('username') || 'Anonymous'}</p>
          <p>{new Date().toLocaleDateString()}</p>
        </div>
      </div>

      <Segment className="share-buttons">
        <Button color="instagram" onClick={() => shareToSocial('instagram')}>
          <Icon name="instagram" /> Share to Instagram
        </Button>
        <Button color="facebook" onClick={() => shareToSocial('facebook')}>
          <Icon name="facebook" /> Share to Facebook
        </Button>
        <Button color="twitter" onClick={() => shareToSocial('twitter')}>
          <Icon name="twitter" /> Share to Twitter
        </Button>
        <Button color="teal" onClick={() => shareToSocial('other')}>
          <Icon name="share alternate" /> Share to Other
        </Button>
      </Segment>
    </div>
  );
}

export default SharePreview; 