import React, { useState } from 'react';
import { Container, Header, Form, Button, Icon, Image, Card, Message } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const API_URL = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_backend_baseUrl_live 
  : process.env.REACT_APP_backend_baseUrl_localTest;

function GmailExtraction() {
  const [brand, setBrand] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [extractedItems, setExtractedItems] = useState([]);
  const [error, setError] = useState(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const navigate = useNavigate();

  const handleAuthorize = async () => {
    try {
      setIsLoading(true);
      // Call your backend to initiate Gmail OAuth
      const response = await axios.get(`${API_URL}/gmail/authorize`);
      // Redirect to Google's OAuth consent screen
      window.location.href = response.data.authUrl;
    } catch (err) {
      setError('Failed to authorize Gmail access. Please try again.');
      setIsLoading(false);
    }
  };

  const handleExtract = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      const response = await axios.post(`${API_URL}/gmail/extract`, {
        brand: brand
      });

      setExtractedItems(response.data.items);
    } catch (err) {
      setError('Failed to extract items. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddToCloset = async (selectedItems) => {
    try {
      setIsLoading(true);
      
      // Send selected items to your backend to be added to the user's closet
      await axios.post(`${API_URL}/closet-clothes/bulk`, {
        items: selectedItems
      });

      navigate('/my-closet');
    } catch (err) {
      setError('Failed to add items to your closet. Please try again.');
      setIsLoading(false);
    }
  };

  return (
    <Container style={{ padding: '2em' }}>
      <Icon 
        name="arrow left" 
        size="large" 
        style={{ cursor: 'pointer', marginBottom: '1em' }} 
        onClick={() => navigate('/my-closet')}
      />
      
      <Header as='h1' textAlign='center'>
        Extract Clothes from Gmail
        <Header.Subheader>
          Import your clothing purchases directly from your Gmail order confirmations
        </Header.Subheader>
      </Header>

      {error && (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <p>{error}</p>
        </Message>
      )}

      {!isAuthorized ? (
        <div style={{ textAlign: 'center', marginTop: '2em' }}>
          <Button
            color='teal'
            size='large'
            onClick={handleAuthorize}
            loading={isLoading}
          >
            <Icon name='google' />
            Authorize Gmail Access
          </Button>
          <p style={{ marginTop: '1em', color: 'grey' }}>
            We'll only access your order confirmation emails
          </p>
        </div>
      ) : (
        <>
          <Form>
            <Form.Field>
              <label>Enter Brand or Store Name</label>
              <Form.Input
                placeholder="e.g., 'Depop', 'Poshmark', 'Patagonia'"
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
            </Form.Field>
            <Button
              color='teal'
              onClick={handleExtract}
              loading={isLoading}
              disabled={!brand}
            >
              Extract Items
            </Button>
          </Form>

          {extractedItems.length > 0 && (
            <div style={{ marginTop: '2em' }}>
              <Header as='h3'>Found Items</Header>
              <Card.Group>
                {extractedItems.map((item, index) => (
                  <Card key={index}>
                    <Image src={item.image} wrapped ui={false} />
                    <Card.Content>
                      <Card.Header>{item.name}</Card.Header>
                      <Card.Meta>{item.size}</Card.Meta>
                    </Card.Content>
                    <Card.Content extra>
                      <Button
                        basic
                        color='teal'
                        onClick={() => handleAddToCloset([item])}
                      >
                        Add to Closet
                      </Button>
                    </Card.Content>
                  </Card>
                ))}
              </Card.Group>
              
              <Button
                color='teal'
                fluid
                style={{ marginTop: '1em' }}
                onClick={() => handleAddToCloset(extractedItems)}
              >
                Add All to Closet
              </Button>
            </div>
          )}
        </>
      )}
    </Container>
  );
}

export default GmailExtraction; 